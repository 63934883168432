<script setup lang="ts" generic="T extends keyof GA4Events">
import { GA4Events } from "nuxt-ga4"
import { UseIntersectionObserverOptions } from "@vueuse/core"
import { currency } from "@formkit/pro"

const props = withDefaults(
  defineProps<{
    items: {
      creative_name: string
      creative_slot: string
      promotion_id: string
      promotion_name: string
    }[]
  }>(),
  {
    observerOptions: {
      threshold: 0.9
    }
  }
)

const { observerOptions, items } = toRefs(props)

const container = ref<HTMLElement>()
const componentEvent = ref<any>()

useIntersectionObserver(
  container,
  (entries, observer) => {
    for (let entry of entries) {
      if (entry.isIntersecting) {
        componentEvent.value?.sendEvent({ clearEcommerce: false })
        observer.disconnect()
      }
    }
  },
  observerOptions
)

const id = inject("id")
const eventName = "view_promotion"
const eventKey = `event_${eventName}_${id}`

/**
 * @description Define view_promotions payload event
 */
const GAViewPromotionPayload = computed<Omit<GA4Events[T], "event">>(() => {
  return {
    event: "view_promotion",
    action: "view_promotion",
    ecommerce: {
      currency: "EUR",
      items: props.items
    }
  }
})
</script>

<template>
  <div ref="container">
    <GAEvent
      event="custom_event"
      :eventKey="eventKey"
      :payload="GAViewPromotionPayload"
      :lazy="true"
      ref="componentEvent"
    >
      <slot />
    </GAEvent>
  </div>
</template>
